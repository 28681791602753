import React, { Component } from 'react';
import Select from 'react-select';
import { getAllFormationTypes } from '../../../service/formation';
  

    const opt = [
    {
        label: "Group 1",
        options: [
        { label: "Group 1, option 1", value: "value_1" },
        { label: "Group 1, option 2", value: "value_2" }
        ]
    },
    { label: "A root option", value: "value_3" },
    { label: "Another root option", value: "value_4" }
    ];
  
  
  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };
  
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  
  class FormationCategoryDropdown extends Component {
    constructor(props) {
        super(props);
        // console.log(props);
        this.state = {
            selectedOption: null,
            types: [],
        }
    }

  //  selected value example: {value: 1, label: 'egyházzenei énekkarok'} from event (not event.target)

    handleChange = (selectedOption) => {
      this.setState((prevState) => ({
        ...prevState,
        selectedOption
      }), () =>
        console.log(`Option selected:`, this.state.selectedOption)
      );
      // TODO: why do we need this?
      this.props.onChange && this.props.onChange(selectedOption);
    };

    async componentDidMount() {
      // console.log(`Selected: ${this.props.value}`);
        const typesResp = await getAllFormationTypes();
        if (typesResp.state == 'SUCCESS') {
            // console.log(typesResp.mess);

            const formationCategories = [];
            typesResp.mess.map((cat) => {
                // formationCategories.push({label: cat.name_fmtype, group: []});


                // console.log(cat);
                if (!formationCategories.hasOwnProperty(cat.id_fmtype)) {
                    formationCategories[cat.id_fmtype] = {};
                    formationCategories[cat.id_fmtype].label = cat.name_fmtype;
                    formationCategories[cat.id_fmtype].value = cat.id_fmtype;
                    formationCategories[cat.id_fmtype].options = [];
                }

                if (cat.id_fstype)
                    formationCategories[cat.id_fmtype].options.push({value: cat.id_fstype, label: cat.name_fstype});
                else {
                    delete formationCategories[cat.id_fmtype].options;
                    formationCategories[cat.id_fmtype].value = cat.id_fmtype;
                }
            });
            
            this.setState({
              types: formationCategories,
            });
            // console.log(this.state.types[1].options[0].value);

        }
        // else
        //     // check if backend is running
        //     console.log(typesResp.mess);
    }
    

    render() {
        return (
            <Select
              defaultValue=""
              placeholder="Válaszza ki a megfelelőt..."
              options={this.state.types}
              name={this.props.name}
              value={this.props.value}
              onChange={this.props.onChange}
              // value={this.state.selectedOption}
              // onChange={this.handleChange}
              // TODO: style like the other dropdown: https://react-select.com/styles#the-classnames-prop
              // styles={{
              //   control: (baseStyles, state) => ({
              //     ...baseStyles,
              //     // borderColor: state.isFocused ? "#18ab42" : null,
              //     fontFamily: "arial",
              //     fontSize: "15px",
              //     height: "50px",
              //     padding: "15px",
              //     border: ".25px",
              //     borderColor: "#535e5a",
              //     borderStyle: "solid",
              //     borderRadius: "5px",
              //   }),
              // }}
            />
        );
    }
  }
export default FormationCategoryDropdown;