import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Formations.css';
import Formation from './Formation';
import { getAllFormationData } from '../../../service/formation';
import toast from 'react-hot-toast';
import { apiBase } from '../../../util/constants';

export default function Formations() {
  const [allFormations, setAllFormations] = useState([]);

  useEffect(async () => {
    const respGetAllFormations = await getAllFormationData();
    // console.log(respGetAllFormations);

    if (respGetAllFormations.state == "SUCCESS")
      setAllFormations(respGetAllFormations.mess);
    else
      toast.error("Az együttesek adatait nem sikerült betölteni.");
}, []);


  return (
    <>
        <h1>Együttesek</h1>
        {/* <Link to={"/adatlap"} state={profileData}>
          Adatlap szerkesztése
        </Link> */}


        {/* TODO: add filter & search & ABCD like iphone */}
        <div className='cards_container'>
          {
            allFormations.length > 0 ?
              <ul className='cards_items'>
                {
                  allFormations.map((fData) => {
                    let imgLink = fData.img ? `${apiBase}/uploads/formations/${fData.img}` : "img/noimg.webp";
                    return(
                      <Formation
                        key={fData.fId}
                        src={imgLink}
                        text={fData.fName}
                        path={"/egyuttesek/" + fData.fId}
                      />
                    );
                  })
              }
              </ul> : <p>Nincsenek megjelenítendő együttesek.</p>
          }
        </div>
    </>
  );
}