import React, { useState, useEffect } from 'react';
import './FormationPage.css';
import { getFormationProfileData } from '../../../service/formation';
import { AiFillInstagram, AiFillYoutube, AiFillFacebook, AiOutlineLink } from 'react-icons/ai';
import { GrPrevious } from 'react-icons/gr';
import { Link, useParams } from 'react-router-dom';
import { apiBase } from '../../../util/constants';

export default function FormationPage(props) {
    const [profileData, setProfileData] = useState({});
    const { id } = useParams();

    useEffect(async () => {
        const respGetProfileData = await getFormationProfileData(id);
        // console.log(respGetProfileData);

        // console.log(linksFromDb);
        setProfileData({
            name: respGetProfileData.mess.formationNameAndDescription[0].name, 
            text: respGetProfileData.mess.formationNameAndDescription[0].text, 
            links: respGetProfileData.mess.formationLinks,
            imgs: respGetProfileData.mess.formationImgs
        });
    }, []);

    // console.log(profileData.links);
    // const window = 
    //     <div className='modal-overlay'>
    //         <div className='modalContainer'>
    //             <div className='closeBtn'><b>&times;</b></div>
    //             <h1>Visszhang kórus</h1>
    //             <img className='formation_page_img' src='img/Visszhang3.jpg'/>
    //             {/* <h1 className='formation_page_name'>
    //                 <span>Visszhang kórus</span>
    //             </h1> */}
    //             <div className='formation_page_content'>
    //                 <p>
    //                     Az idén 45 éves Visszhang kórust fennállása alatt több karmester is vezette (<i>Tóth István, Szalay Zoltán, Magyari Zita, Ercsey-Ravasz Ferenc</i>), akik munkájukkal a tagok számára a közösségépítést és a hallgatóságnak a minőségi kultúra elérhetőségét biztosították. 2008 óta <i>Molnár Botond</i> irányítja a kolozsvári magyar egyetemistákat megszólító énekegyüttest.
    //                 </p>
    //                 <h2>Linkek</h2>
    //                     <ul>
    //                         <AiOutlineLink style={{fontSize: '30px'}}/> 
    //                         <a href="https://www.visszhangkorus.ro" target="_blank">
    //                             visszhangkorus.ro
    //                         </a><br/>
    //                         <AiFillFacebook style={{fontSize: '30px'}}/> 
    //                         <a href="https://www.facebook.com/VisszhangKorus" target="_blank">
    //                             VisszhangKorus
    //                         </a><br/>
    //                         <AiFillInstagram style={{fontSize: '30px'}}/> 
    //                         <a href="https://www.instagram.com/visszhangkorus" target="_blank">
    //                             visszhangkorus
    //                         </a><br/>
    //                         <AiFillYoutube style={{fontSize: '30px'}}/>
    //                         <a href="https://www.youtube.com/@VisszhangKorus" target="_blank">
    //                             @VisszhangKorus
    //                         </a>
    //                     </ul>
    //                 {/* 
    //                 https://www.visszhangkorus.ro
    //                 https://www.youtube.com/@VisszhangKorus
    //                 https://www.facebook.com/VisszhangKorus
    //                 https://www.instagram.com/visszhangkorus/ 
    //                 */}
    //             </div>
    //         </div>
    //     </div>

    return (
        <div>
            <Link to="/egyuttesek"><GrPrevious/>&nbsp;Vissza az együttesekhez</Link>
            <h1>{profileData.name}</h1>
            {/* <img className='formation_page_img' src='img/Visszhang3.jpg'/> */}
            
            {/* TODO: ha több feltöltött kép van, carousel */}
            {
                profileData.imgs?.length > 0 ? 
                    <img style={{width: '100%', maxHeight: '500px', display: 'block', objectFit: 'cover'}} src={`${apiBase}/uploads/formations/${profileData.imgs[0].new_img_name}`}/>
                    : null
            }

            <div>
                {/* {profileData.text} */}
                <div dangerouslySetInnerHTML={{ __html: profileData.text }} />
                <h2>Linkek</h2>
                    {profileData.links?.length > 0 ?
                        <table>
                            <tbody>
                            {
                                profileData.links.map((item) => {
                                    var clickLink = item.wtype_name === "Youtube" ? `https://www.youtube.com/${item.url_website}`
                                    : item.wtype_name === "Facebook" ? `https://www.facebook.com/${item.url_website}`
                                    : item.wtype_name === "Instagram" ? `https://www.instagram.com/${item.url_website}`
                                    : item.url_website;

                                    var TagName = item.wtype_name === "Youtube" ? AiFillYoutube 
                                    : item.wtype_name === "Facebook" ? AiFillFacebook 
                                    : item.wtype_name === "Instagram" ? AiFillInstagram 
                                    : AiOutlineLink;

                                    return(
                                        <tr key={item.wtype_name}>
                                            <td style={{ paddingRight: '5px' }}>
                                                <TagName style={{fontSize: '30px'}}/>
                                            </td>
                                            <td>
                                                <a href={clickLink} target="_blank">
                                                    {item.url_website}
                                                </a>
                                            </td>
                                        </tr>
                                    );
                                    }
                                )
                            }
                            </tbody>
                        </table>
                     : <p>Nincsenek megjelenítendő linkek.</p>}


                    {/* <table>
                        <tr>
                            <td style={{ paddingRight: '5px' }}>
                                <AiOutlineLink style={{fontSize: '30px'}}/> 
                            </td>
                            <td>
                                <a href="https://www.visszhangkorus.ro" target="_blank">
                                    visszhangkorus.ro
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '5px' }}>
                                <AiFillFacebook style={{fontSize: '30px'}}/> 
                            </td>
                            <td>
                                <a href="https://www.facebook.com/VisszhangKorus" target="_blank">
                                    VisszhangKorus
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '5px' }}>
                                <AiFillInstagram style={{fontSize: '30px'}}/> 
                            </td>
                            <td>
                                <a href="https://www.instagram.com/visszhangkorus" target="_blank">
                                    visszhangkorus
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '5px' }}>
                                <AiFillYoutube style={{fontSize: '30px'}}/>
                            </td>
                            <td>
                                <a href="https://www.youtube.com/@VisszhangKorus" target="_blank">
                                    @VisszhangKorus
                                </a>
                            </td>
                        </tr>
                    </table>          */}
            </div>
        </div>
    );
}